/** @format */

export const getCityUrl = (name: string, locale: string, type: 'campus' | 'dv' | 'altido', country: string) => {
  switch (type) {
    case 'campus':
      return getCampusUrl(name, locale);
    case 'dv':
      return getDVUrl(name, locale);
    case 'altido':
      return getAltidoUrl(name, country, locale);
    default:
      throw new Error('Invalid city type');
  }
};
const getDVBaseUrl = (locale: string) => {
  const baseUrl = 'https://coliving.joivy.com';
  switch (locale) {
    case 'it':
      return `${baseUrl}/it/affitto-stanza-`;
    case 'fr':
      return `${baseUrl}/fr/colocation-`;
    case 'es':
      return `${baseUrl}/es/alquiler-habitacion-`;
    case 'en':
    default:
      return `${baseUrl}/en/rent-room-`;
  }
};
const getDVUrl = (name: string, locale: string) => `${getDVBaseUrl(locale)}${getCityName(name, locale)}/`;

const getCampusUrl = (name: string, locale: string) => {
  const baseurl = locale === 'it' ? 'https://studenthousing.joivy.com/it/' : 'https://studenthousing.joivy.com/en/';
  const cityname = name === 'como' ? 'como-campus' : getCityName(name, locale);
  return `${baseurl}${cityname}/`;
};

const getAltidoUrl = (name: string, country: string, locale: string) => {
  const isCountry = country === name;
  const baseurl = 'https://www.stayaltido.com/book/step1?';

  if (isCountry) return `${baseurl}country=${country !== 'en' ? country : 'eng'}&lang=${locale === 'it' ? 'it' : 'en'}`;
  return `${baseurl}omnibar=district%3b${name}&lang=${locale === 'it' ? 'it' : 'en'}`;
};

// TODO: refactor
const getCityName = (name: string, locale: string): string => {
  switch (name) {
    case 'bologna':
      if (locale === 'fr') return 'bologne';
      if (locale === 'es') return 'bolonia';
      return 'bologna';
    case 'milan':
      if (locale === 'it') return 'milano';
      return 'milan';
    case 'florence':
      if (locale === 'it') return 'firenze';
      if (locale === 'es') return 'florencia';
      return 'florence';
    case 'padua':
      if (locale === 'it') return 'padova';
      if (locale === 'fr') return 'padoue';
      return 'padua';
    case 'rome':
      if (locale === 'it' || locale === 'es') return 'roma';
      return 'rome';
    case 'turin':
      if (locale === 'it') return 'torino';
      return 'turin';
    case 'venice':
      if (locale === 'it') return 'venezia';
      return 'venice';
    case 'lyon':
      if (locale === 'it') return 'lione';
      return 'lyon';
    case 'paris':
      if (locale === 'it') return 'parigi';
      return 'paris';
    case 'toulouse':
      if (locale === 'it') return 'tolosa';
      return 'toulouse';
    case 'trento':
      if (locale === 'fr') return 'trente';
      return 'trento';
    case 'lisbon':
      if (locale === 'it') return 'lisbona';
      if (locale === 'fr') return 'lisbonne';
      if (locale === 'es') return 'lisboa';
      return 'lisbon';
    case 'bordeaux':
      if (locale === 'es') return 'burdeos';
      return 'bordeaux';
    default:
      return name;
  }
};

export const formatCountryFromISO = (country: string) => {
  switch (country) {
    case 'it':
      return 'italy';
    case 'en':
      return 'england';
    case 'es':
      return 'spain';
    case 'fr':
      return 'france';
    case 'pt':
      return 'portugal';
    case 'gb':
      return 'united_kingdom';
    case 'ie':
      return 'ireland';
    case 'sco':
      return 'scotland';
    default:
      throw new Error(`Invalid country code passed to formatCountry: ${country}`);
  }
};
